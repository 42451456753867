<template>
    <div>
        <div v-if="loadingCards">
            <div class="rounded p-3 d-flex bg-light align-items-center font-weight-bold">
                <b-spinner variant="primary" small type="grow" v-if="loadingCards" class="mr-2"></b-spinner>
                Loading payment details, please wait
            </div>
        </div>
        <div v-else>
            <div class="alert alert-danger" variant="danger" v-if="cards.length === 0">
                {{ empty }}
            </div>

            <div v-for="card in cards" class="rounded bg-light mb-1 p-3 d-flex flex-row align-items-center">
                <b-icon-credit-card class="mr-3 text-primary flex-wrap"></b-icon-credit-card>
                <div class="flex-fill">
                    <div>
                        <strong>{{ card.card.brand }}</strong> ending {{ card.card.last4 }}
                    </div>
                    <div class="text-muted">
                        <small>Expiry {{ card.card.exp_month }}/{{ card.card.exp_year }}</small>
                    </div>
                </div>
            </div>

            <b-button variant="outline-primary" size="sm" class="mt-1" v-on:click="addCard">{{ cards.length === 0 ? "Add payment method" :
                "Update payment method" }}
            </b-button>
        </div>

        <b-modal :no-close-on-backdrop="true" :hide-header=true :hide-footer=true v-model="showModal">
            <div class="text-right position-absolute" style="right: 10px; top:10px;">
                <a class="btn btn-sm text-primary" @click="() => showModal = false">
                    <b-icon-x-circle></b-icon-x-circle>
                </a>
            </div>
            <div>
                <h5 class="border-bottom pb-2 text-primary">Add payment method</h5>
                <div v-show="loadingCardForm" class="p-3">
                    <b-spinner variant="primary" small type="grow"></b-spinner>
                    Saving details
                </div>
                <div v-show="!loadingCardForm" class="p-2">

                    <b-form-group
                            label="Card number:"
                            label-size="sm">
                        <div ref="cardNumber" class="form-control">
                            <b-spinner variant="primary" small type="grow"></b-spinner>
                        </div>
                    </b-form-group>
                    <b-form-group
                            label="Card Expiry:"
                            label-size="sm">
                        <div ref="cardExpiry" class="form-control">
                            <b-spinner variant="primary" small type="grow"></b-spinner>
                        </div>
                    </b-form-group>
                    <b-form-group
                            label="Card CVC (Last 3 digits on back of card):"
                            label-size="sm">
                        <div ref="cardCvc" class="form-control">
                            <b-spinner variant="primary" small type="grow"></b-spinner>
                        </div>
                    </b-form-group>

                    <b-form-group
                            label="Card postcode:"
                            label-size="sm">
                        <b-form-input placeholder="Postcode card is registered to" v-model="cardPostcode">
                        </b-form-input>
                    </b-form-group>
                    <error-display v-model="errors" ident="card"></error-display>
                    <div class="text-right">
                        <b-form-group class="mb-0">
                            <b-button type="submit" v-on:click="saveCard" variant="primary">Save</b-button>
                        </b-form-group>
                    </div>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
    import {getResource, saveResource} from "../../modules/api/methods";
    import {paymentMethods, setupIntent} from "../../modules/api/endpoints";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import {containsErrors} from "../../modules/helpers/helpers";

    const style = {
        base: {
            'fontSize': '16px',
            'color': '#495057',
            'fontFamily': 'apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif'
        }
    };

    export default {
        name: "StripeCardCollection",
        components: {ErrorDisplay},
        props: {
            value: null,
            empty: {
                type: String,
                default: "You have no payment cards associated with your account."
            }
        },
        data: function () {
            return {
                selectedCard: this.value,
                loadingCards: false,
                showModal: false,
                cards: [],
                stripe: null,
                errors: {},
                cardNumber: null,
                cardExpiry: null,
                cardCvc: null,
                cardPostcode : null,
                loadingCardForm: false,
            }
        },
        mounted() {
            this.loadCards();

        },
        watch: {},
        methods: {

            addCard() {
                this.loadStripe();
            },
            filtersUpdated(filters) {
                this.request = filters;
            },
            loadStripe() {
                this.showModal = true;
                var vm = this;
                vm.errors = {};
                //WAIT FOR MODAL TO SHOW

                window.setTimeout(function () {
                    vm.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
                    var elements = vm.stripe.elements({locale: 'en'});
                    vm.cardNumber = elements.create('cardNumber', {style: style});
                    vm.cardExpiry = elements.create('cardExpiry', {style: style});
                    vm.cardCvc = elements.create('cardCvc', {style: style});
                    vm.cardNumber.mount(vm.$refs.cardNumber);
                    vm.cardExpiry.mount(vm.$refs.cardExpiry);
                    vm.cardCvc.mount(vm.$refs.cardCvc);
                }, 1000);

            },
            saveCard() {
                let vm = this;
                if(vm.cardPostcode == null || vm.cardPostcode.length < 5){
                    vm.errors = { card : ["Please enter a valid postcode"]};
                    return;
                }
                this.loadingCardForm = true;
                vm.errors = {};
                getResource(setupIntent).then((resp) => {
                    var setupIntent = resp.data.success;
                    this.loadingCardForm = true;
                    this.stripe
                        .confirmCardSetup(setupIntent.client_secret, {
                            payment_method: {
                                card: vm.cardNumber,
                                billing_details: {
                                    name: vm.$store.state.user.organisation.name,
                                    address : {
                                        postal_code : vm.cardPostcode
                                    }
                                },
                            },
                        })
                        .then(function (result) {
                            if (result.error != null) {
                                console.log(result.error);
                                vm.errors = {'card': [result.error.message]};
                                vm.loadingCardForm = false;
                            } else {
                                vm.$emit('input', result.setupIntent.payment_method);
                                vm.loadingCardForm = false;
                                vm.loadCards();
                                vm.showModal = false;
                            }
                        });
                }).catch((err) => {
                    console.log(err);
                    if (containsErrors(err)) {
                        vm.errors = {'card': [result.error.message]};
                    } else {
                        vm.errors = {'card': ['Unable to save this payment card, please try again']};
                    }
                    vm.loadingCardForm = false;
                }).finally(() => {
                });
            },
            loadCards() {
                this.loadingCards = true;
                getResource(paymentMethods).then((resp) => {
                    console.log(resp);
                    this.cards = resp.data.success;
                    if (resp.data.success.length > 0) {
                        console.log(resp.data.success[0]);
                        this.$emit('input', resp.data.success[0]);
                    }

                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.loadingCards = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
