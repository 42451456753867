<template>
    <div>
        <surround-loader :is-loading="isLoading">
            <div class="p-4">
                <h2 class="mb-4">Payment</h2>

                <p>Access Right to Work is a Pay As You Go service whereby you only pay for Right to Work checks you
                    have
                    initiated.</p>

                <div v-if="this.$store.state.user.organisation.workspace_id == null">

                    <p>Before you can begin to perform Right to Work checks, please supply a payment method below.</p>

                    <p>The card on file will be billed monthly in arrears.</p>

                    <stripe-card-collection v-model="paymentMethod" class="mb-4"
                                            empty="Please enter a payment method to your account to proceed"></stripe-card-collection>

                </div>
                <div v-else>
                    <p>You will be invoiced monthly in arrears and an additional description line will be added to your current Access Group invoice.</p>

                </div>
                <h4>Cost summary</h4>
                <pricing-display></pricing-display>

            </div>
            <div class="p-4  d-flex justify-content-between">
                <b-button :to="{name:'setup_contract'}" variant="outline-secondary">
                    Back
                </b-button>
                <b-button @click="saveForm" :disabled="paymentMethod == null && this.$store.state.user.organisation.workspace_id == null" :to="{name:'setup_payment'}"
                          variant="success">
                    Proceed to launch
                </b-button>
            </div>
        </surround-loader>
    </div>
</template>

<script>
    import Vue from "vue";
    import StripeCardCollection from "../components/StripeCardCollection";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import {organisationSetupPayment} from "../../modules/api/endpoints";
    import {containsErrors} from "../../modules/helpers/helpers";
    import {getResource} from "../../modules/api/methods";
    import SurroundLoader from "../SurroundLoader";
    import PricingDisplay from "./PricingDisplay";

    export default {
        name: 'setup_payment',
        data: function () {
            return {
                paymentMethod: null,
                errors: {},
                isLoading: false,
            }
        },
        methods: {
            saveForm() {
                var vm = this;
                vm.errors = {};
                vm.isLoading = true;
                getResource(organisationSetupPayment).then(function (resp) {
                    //UPDATE THE ORG TO ALLOW TO PROCEED TO NEXT STEP
                    console.log(resp.data.success.data);
                    vm.$store.dispatch('updateOrganisation', resp.data.success.data).then(() => {
                        vm.$router.push({name: 'setup_launch'}).catch(error => {});
                    });
                }).catch(function (err) {
                    console.log(err);
                    if (containsErrors(err)) {
                        vm.errors = err.data.errors;
                    }
                }).finally(function () {
                    vm.isLoading = false;
                })
            },
        },

        components: {PricingDisplay, SurroundLoader, ErrorDisplay, StripeCardCollection},
    }
</script>

